import { FieldTypeId } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { isEmpty } from "lodash";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";

export function getFeatureDataPath(
  selectedIndex: [number, number],
  investmentSeriesData: InvestmentProductSeriesDTO,
  selectedFeature: InvestmentProductConfigurationFeature,
) {
  const investmentSeriesDataPath =
    `investmentProductSeriesData.${selectedIndex[0]}` as SeriesPath;
  const investmentSeriesDataStatusPath =
    `${investmentSeriesDataPath}.dataStatus` as SeriesPath;
  const investmentSeriesDataHighlightTypePath =
    `${investmentSeriesDataPath}.highlightType` as SeriesPath;
  const investmentSeriesDataModifiedPath =
    `${investmentSeriesDataPath}.isModified` as SeriesPath;
  const sectionPath =
    `${investmentSeriesDataPath}.sections.${selectedIndex[1]}` as SeriesPath;
  const sectionModifiedPath = `${sectionPath}.isModified` as SeriesPath;
  const featureIndex = investmentSeriesData.sections![
    selectedIndex[1]
  ].features!.findIndex(
    (item: InvestmentProductConfigurationFeature) =>
      item.id === selectedFeature.id,
  );
  const featurePath = `${sectionPath}.features.${featureIndex}` as SeriesPath;
  const featureAllowedVisibilitySettingPath =
    `${featurePath}.allowedVisibilitySetting` as SeriesPath;
  const featureViewingGroupSelectionsPath =
    `${featurePath}.viewingGroupSelections` as SeriesPath;
  const featureModifiedPath = `${featurePath}.isModified` as SeriesPath;
  const featureProductDataPath =
    `${featurePath}.productData.value` as SeriesPath;
  const featureProductHighlightTypePath =
    `${featurePath}.productData.highlightType` as SeriesPath;
  const featureProductDataStatusPath =
    `${featurePath}.productData.dataStatus` as SeriesPath;
  const featureProductDataModifiedPath =
    `${featurePath}.productData.isModified` as SeriesPath;
  const featureProductDataPublishedPath =
    `${featurePath}.productData.publishedValue` as SeriesPath;
  const featureProductDataAdditionDataPath =
    `${featurePath}.productData.additionalValue` as SeriesPath;
  const featureProductDataPublishedAdditionDataPath =
    `${featurePath}.productData.publishedAdditionalValue` as SeriesPath;
  const dropDownFeaturePath = `${sectionPath}.features.0` as SeriesPath;

  return {
    sectionPath,
    featurePath,
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataStatusPath,
    featureProductDataModifiedPath,
    featureProductDataAdditionDataPath,
    investmentSeriesDataStatusPath,
    investmentSeriesDataHighlightTypePath,
    featureProductDataPublishedPath,
    featureProductDataPublishedAdditionDataPath,
    investmentSeriesDataPath,
    featureAllowedVisibilitySettingPath,
    featureViewingGroupSelectionsPath,
    dropDownFeaturePath,
    investmentSeriesDataModifiedPath,
  };
}

export function getShowHideNoRoleFieldStatus(
  getValues: UseFormGetValues<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>,
  dropdownFeaturePath: SeriesPath,
) {
  const dropdownConfigFeature = getValues(
    dropdownFeaturePath,
  ) as InvestmentProductConfigurationFeature;
  const isDropdownFeature =
    dropdownConfigFeature &&
    dropdownConfigFeature.fieldType?.id === FieldTypeId.Dropdown;

  return (
    isDropdownFeature && dropdownConfigFeature.productData?.value === "NoRole"
  );
}

export function setDefaultValueForNoRoleFields(
  investmentProductSeriesData: InvestmentProductSeriesDTO[],
  getValues: UseFormGetValues<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>,
  setValue: UseFormSetValue<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>,
) {
  const noRoleSectionIdx = 2;
  let featureDropdownValue = "";

  for (
    let seriesIdx = 0;
    seriesIdx < investmentProductSeriesData.length;
    seriesIdx++
  ) {
    const noRoleFeatures =
      investmentProductSeriesData[seriesIdx].sections?.[noRoleSectionIdx]
        .features;

    for (
      let featureIdx = 0;
      featureIdx < (noRoleFeatures ?? []).length;
      featureIdx++
    ) {
      const featurePath = `investmentProductSeriesData.${seriesIdx}.sections.${noRoleSectionIdx}.features.${featureIdx}`;
      const featureFieldTypePath = `${featurePath}.fieldType.id` as SeriesPath;
      const featureProductDataValuePath =
        `${featurePath}.productData.value` as SeriesPath;
      const featureProductPublishedDataPath =
        `${featurePath}.productData.publishedValue` as SeriesPath;
      const featureValue = getValues(featureProductDataValuePath) ?? "";
      const featurePublishedValue =
        getValues(featureProductPublishedDataPath) ?? "";
      const featureFieldType = getValues(featureFieldTypePath);

      if (featureIdx === 0) {
        featureDropdownValue = featureValue as string;
        continue;
      }

      if (featureDropdownValue !== "NoRole") break;

      switch (featureFieldType) {
        case FieldTypeId.TextLong:
          setValue(
              featureProductDataValuePath,
              isEmpty(featureValue) ? "N/A" : featurePublishedValue,
          );
          break;
        case FieldTypeId.YesNo:
          setValue(
              featureProductDataValuePath,
              isEmpty(featureValue) ? "false" : featurePublishedValue,
          );
          break;
      }
    }
  }
}
