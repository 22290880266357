import { useSupplier } from "@hooks/useSupplier";
import { ViewInvestmentProduct } from "./view";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useIsNew } from "@hooks/useIsNew";
import { RootState, useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";
import { useUserPermissions } from "@hooks/userUserPermission";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import { useMount, useUnmount } from "react-use";
import {
  resetForbidden,
  resetInvestmentProduct,
  updateSelectedTab,
} from "@redux/slices/product/investment-product";
import { EditInvestmentProduct } from "./edit";

export const InvestmentProductDetails = () => {
  const {
    investmentProductUI: { isEditMode, isForbidden },
  } = useSelector((state: RootState) => state.investmentProduct);
  const isNew = useIsNew();
  const { supplierId } = useParams<{ supplierId: string }>();
  const { loadSupplier } = useSupplier();
  const { user, isSupplierBasic, canViewProduct } = useUserPermissions();
  const accessDenied = isSupplierBasic
    ? user?.userMetadata?.supplier_id !== supplierId
    : !canViewProduct;
  const dispatch = useAppDispatch();

  useMount(() => {
    dispatch(resetInvestmentProduct());
    dispatch(
      updateSelectedTab({
        selectedTab: 0,
      }),
    );
  });

  useEffect(() => {
    if (supplierId && (isNew || isEditMode)) {
      loadSupplier(supplierId);
    }
    // eslint-disable-next-line
  }, [supplierId]);
  useUnmount(() => {
    dispatch(resetForbidden());
  });

  if (accessDenied || isForbidden)
    return <AccessDeniedDialog />;

  if (isNew || isEditMode) return <EditInvestmentProduct />;
  return <ViewInvestmentProduct />;
};
