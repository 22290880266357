import { AnalysisToolBarContainer } from "@components/analysis-nav-bar/portal";
import { SHButton, SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import AccessDeniedDialog from "@components/dialogs/access-denied";
import UnsavedDialog from "@components/dialogs/unsaved";
import { PageRoutes } from "@constants";
import { useIsNew } from "@hooks/useIsNew";
import { useManagedAccount } from "@hooks/useManagedAccount";
import { useSupplier } from "@hooks/useSupplier";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ManagedAccountBannerInfo } from "@layouts/managed-account-layout/managed-account-banner-info";
import {
  Step,
  StepperButtonState,
  StepperLayout,
  StepperLayoutRef,
} from "@layouts/stepper";
import { ManagedAccountStep } from "@models/managed-accounts/enums/step";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ManagedAccountCompleteButton } from "../components/buttons/complete";
import { ManagedAccountExportPDFButton } from "../components/buttons/export-pdf";
import { ManagedAccountFeeAnalysis } from "./steps/fee-analysis";
import { ManagedAccountPortfolioSetup } from "./steps/portfolio-setup";
import { ManagedAccountSummary } from "./steps/summary";

const { PortfolioSetup, FeeAnalysis, Summary } = ManagedAccountStep;
export const ManagedAccountDetail = () => {
  const isNew = useIsNew();
  const navigate = useNavigate();
  const { managedAccountId } = useParams<{ managedAccountId: string }>();

  const prevManagedAccountIdRef = useRef(managedAccountId);
  const stepperRef = useRef<StepperLayoutRef | null>(null);
  const lastedAction = useRef<"save" | "next" | "back" | null>(null);

  const {
    isCompleted,
    managedAccountSteps,
    loadManagedAccountSteps,
    loadManagedAccountBannerInfo,
    setIsAccessDenied,
    setSelectedStepIndex,
    setManagedAccountSteps,
    resetStore,
    managedAccount,
    ui: managedAccountUIStates,
  } = useManagedAccount();

  const { user } = useUserPermissions();
  const { supplierData, loadSupplier, refreshSupplier } = useSupplier();

  const {
    steps,
    selectedStepIndex,
    isDirty,
    isValid,
    isAccessDenied,
    isSubmitting,
    isLoadingSteps,
    isLoadingBanner,
  } = managedAccountUIStates;

  const [buttonState, setButtonState] = useState<StepperButtonState>({
    isCanNext: true,
    isCanPrevious: false,
  });

  const initialSteps: Step[] = [
    {
      key: PortfolioSetup,
      label: "Portfolio setup",
      component: ManagedAccountPortfolioSetup,
      hidden: steps[PortfolioSetup].isHidden,
      unHighlighted: steps[PortfolioSetup].isUnHighlighted,
    },
    {
      key: FeeAnalysis,
      label: "Fee analysis",
      component: ManagedAccountFeeAnalysis,
      hidden: steps[FeeAnalysis].isHidden,
      unHighlighted: steps[FeeAnalysis].isUnHighlighted,
    },
    {
      key: Summary,
      label: "Summary",
      component: ManagedAccountSummary,
      hidden: steps[Summary].isHidden,
      unHighlighted: steps[Summary].isUnHighlighted,
    },
  ];

  const isLoadingStepLayout =
    !supplierData ||
    isLoadingBanner ||
    isLoadingSteps ||
    isAccessDenied === undefined;

  const loadData = async (managedAccountId: string) => {
    const bannerInfo = await loadManagedAccountBannerInfo(managedAccountId);
    if (bannerInfo) await loadManagedAccountSteps(managedAccountId);
  };

  useEffect(() => {
    if (user?.userMetadata?.supplier_id)
      loadSupplier(user.userMetadata.supplier_id);
    return () => {
      refreshSupplier();
    };
    // eslint-disable-next-line
  }, [user?.userMetadata?.supplier_id]);

  useEffect(() => {
    if (
      managedAccountId &&
      managedAccountId !== "new" &&
      prevManagedAccountIdRef.current !== "new"
    ) {
      loadData(managedAccountId);
    } else {
      setManagedAccountSteps({
        steps: [],
      });
      setIsAccessDenied(false);
    }
    prevManagedAccountIdRef.current = managedAccountId;
    // eslint-disable-next-line
  }, [managedAccountId]);

  useEffect(() => {
    return () => {
      resetStore();
    };
    // eslint-disable-next-line
  }, []);

  if (isAccessDenied) return <AccessDeniedDialog />;

  return (
    <>
      <AnalysisToolBarContainer>
        <SHStack
          spacing={"25px"}
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          height="100%"
        >
          <ManagedAccountBannerInfo />
          {isLoadingSteps ? (
            <SHSkeleton height={32} width={100} />
          ) : (
            <>
              <SHStack
                direction="row"
                justifyContent="flex-start"
                alignItems={"center"}
                spacing={"15px"}
              >
                <SHButton
                  variant="outlined"
                  size="extraMedium"
                  disabled={isSubmitting}
                  onClick={() => {
                    stepperRef?.current &&
                      stepperRef.current.onExit &&
                      stepperRef.current.onExit();
                    navigate(PageRoutes.managedAccountsAnalysis);
                  }}
                >
                  Exit
                </SHButton>
                {buttonState.isCanPrevious && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    disabled={isSubmitting}
                    isLoading={lastedAction.current === "back" && isSubmitting}
                    onClick={() => {
                      lastedAction.current = "back";
                      stepperRef?.current &&
                        stepperRef.current.movePreviousStep();
                    }}
                  >
                    Back
                  </SHButton>
                )}
                {!isCompleted && (
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    disabled={!isValid || isSubmitting || (!isNew && !isDirty)}
                    isLoading={lastedAction.current === "save" && isSubmitting}
                    onClick={() => {
                      lastedAction.current = "save";
                      stepperRef?.current && stepperRef.current.onSubmit();
                    }}
                  >
                    Save
                  </SHButton>
                )}
                {buttonState.isCanNext ? (
                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    disabled={
                      !isValid || isSubmitting || selectedStepIndex === 1
                    } //TODO: Grey out the next button in step 2
                    isLoading={isSubmitting}
                    onClick={() => {
                      lastedAction.current = "next";
                      stepperRef?.current && stepperRef.current.moveNextStep();
                    }}
                  >
                    Next
                  </SHButton>
                ) : isCompleted ? (
                  <ManagedAccountExportPDFButton />
                ) : (
                  <ManagedAccountCompleteButton />
                )}
              </SHStack>
            </>
          )}
        </SHStack>
      </AnalysisToolBarContainer>

      <StepperLayout
        isUsePortal
        ref={stepperRef}
        steps={initialSteps}
        selectedStepIndex={
          managedAccountSteps === undefined ? -1 : selectedStepIndex
        }
        isLoading={isLoadingStepLayout}
        isCompleted={isCompleted}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        isValid={isValid}
        onChangeSelectedStepIndex={setSelectedStepIndex}
        onChangeButtonState={(buttonState) => {
          setButtonState(buttonState);
        }}
        isShowFooterAction={
          !isLoadingStepLayout &&
          selectedStepIndex === 0 &&
          (isNew || !!managedAccount)
        }
      />

      <UnsavedDialog isDirty={isDirty} />
    </>
  );
};
