import { FieldTypeId, HighlightType } from "@models/configuration";
import {
  toNativeValue,
  toStringValue,
} from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { Controller, useFormContext } from "react-hook-form";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { S3ObjectDTO } from "@models/core";
import { debounce, isEmpty, isEqual } from "lodash";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import {
  SHDivider,
  SHLabel,
  SHStack,
  SHTypography,
  SHUploadImage,
} from "@components/design-systems";
import { GridImageGallery } from "@components/grid-image-gallery";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import { useNotification } from "@hooks/useNotification";
import { FileRejection } from "react-dropzone";
import { renameFile } from "@utils/data-type/file";
import { useState } from "react";
import { ImageSVG } from "@components/svgs";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import {
  getFeatureDataPath,
  getShowHideNoRoleFieldStatus,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/utils";

interface SeriesFeatureGalleryProps {
  feature: InvestmentProductConfigurationFeature;
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  selectedIndex: [number, number];
  isDisabled?: boolean;
}

const SeriesFeatureGallery = ({
  feature,
  selectedIndex,
  investmentProductSeriesData,
  isDisabled = false,
}: SeriesFeatureGalleryProps) => {
  const { watch, setValue, getValues, control } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const { notify } = useNotification();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const {
    investmentSeriesDataModifiedPath,
    sectionPath,
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataModifiedPath,
    investmentSeriesDataHighlightTypePath,
    featureProductDataPublishedPath,
    featureProductDataStatusPath,
    featureViewingGroupSelectionsPath,
    dropDownFeaturePath,
  } = getFeatureDataPath(selectedIndex, investmentProductSeriesData, feature);
  const sectionName = `${sectionPath}.name`;
  const productDataValue = watch(featureProductDataPath as SeriesPath);
  const { isShowOrangeDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductHighlightTypePath as SeriesPath,
      dataStatus: featureProductDataStatusPath as SeriesPath,
    },
  });

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    const isModified = !isEqual(feature.viewingGroupSelections, newValue);
    setValue(investmentSeriesDataModifiedPath, isModified);
    setValue(sectionModifiedPath, isModified);
    setValue(featureModifiedPath, isModified);
    setValue(featureProductDataModifiedPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );
  const handleInputImageChange = (inputValue: S3ObjectDTO[]) => {
    const publishedProductData = getValues(
      featureProductDataPublishedPath as SeriesPath,
    ) as string;
    const publishedDataImages = JSON.parse(
      Boolean(publishedProductData) ? publishedProductData : "[]",
    ) as S3ObjectDTO[];
    const inputValueStr =
      inputValue.length > 0
        ? toStringValue(inputValue, FieldTypeId.Slideshow)
        : "";
    const isModified = !isEqual(inputValueStr, feature.productData?.value);

    const highlightType = !isEqual(inputValue, publishedDataImages)
      ? HighlightType.Edited
      : null;

    setValue(sectionModifiedPath, true);
    setValue(investmentSeriesDataModifiedPath, true);
    setValue(investmentSeriesDataHighlightTypePath, highlightType);
    setValue(featureModifiedPath, isModified);
    setValue(featureProductDataModifiedPath, isModified);
    setValue(featureProductHighlightTypePath, highlightType);
    setValue(featureProductDataStatusPath, null);
    setValue(featureProductDataPath, inputValueStr);
  };
  const handleOnUpload = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    setUploadMessage(
      !isEmpty(fileRejections)
        ? "Unsupported file format. Please upload another file."
        : null,
    );
    if (isEmpty(acceptedFiles)) return;

    setIsUploading(true);
    let newValues: S3ObjectDTO[] = [];
    let apis: any[] = [];
    const value = (toNativeValue(
      productDataValue as string,
      FieldTypeId.Slideshow,
    ) || []) as S3ObjectDTO[];
    acceptedFiles.forEach((file) => {
      const form = new FormData();
      form.append("file", renameFile(file));
      apis.push(uploadAvatar(form));
    });
    const responses = await Promise.all(apis);
    responses.forEach(({ data, message }) => {
      if (data) {
        newValues.push({
          key: data?.key,
          url: data?.url,
        });
      } else {
        notify(message, {
          variant: "error",
          close: true,
        });
      }
    });
    setIsUploading(false);
    handleInputImageChange([...value, ...newValues]);
  };
  const handleOnDelete = async (s3Key: string) => {
    if (!s3Key) return;
    const value = (toNativeValue(
      productDataValue as string,
      FieldTypeId.Slideshow,
    ) || []) as S3ObjectDTO[];
    const { isSuccess, message } = await deleteAvatar(s3Key);
    if (isSuccess) {
      handleInputImageChange(value.filter((img) => img.key !== s3Key));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  if (
    !investmentProductSeriesData ||
    getShowHideNoRoleFieldStatus(getValues, dropDownFeaturePath)
  ) {
    return <></>;
  }

  return (
    <SHStack>
      <SHDivider />
      <SHStack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        mt={2}
        mb={4}
        height={"120px"}
        gap={2}
      >
        <SHStack
          sx={{
            height: "100%",
            width: "100%",
          }}
          justifyContent={"space-between"}
        >
          <SHLabel
            dotGroupProps={{
              orangeDot: isShowOrangeDot,
            }}
            label={
              <SHStack direction={"row"} alignItems={"center"} gap={1}>
                <SHTypography variant={"subtitle2"} p={0}>
                  {feature.name}
                </SHTypography>
                <ImageSVG />
              </SHStack>
            }
          />
          {feature.allowedVisibilitySetting && (
            <SHStack direction={"row"} alignItems={"center"}>
              <SHLabel
                label={
                  <SHTypography disabled={isDisabled} variant="subtitle2">
                    Visible to
                  </SHTypography>
                }
              />
              <Controller
                name={featureViewingGroupSelectionsPath as SeriesPath}
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SelectViewingGroups
                    sx={{
                      width: "70%",
                    }}
                    onChange={(value) => {
                      other.onChange(value);
                      debounceVisibilityChange(value);
                    }}
                    value={
                      (getValues(
                        featureViewingGroupSelectionsPath as SeriesPath,
                      ) as ViewingGroupSelectionDTO[] | null) || []
                    }
                    viewingGroupData={feature.viewingGroupSelections ?? []}
                    disabled={isDisabled}
                  />
                )}
              />
            </SHStack>
          )}
        </SHStack>
        <SHUploadImage
          buttonText="Click to add"
          objectFit="contain"
          isUploading={isUploading}
          src={""}
          isCircular={false}
          error={!!uploadMessage}
          helperText={uploadMessage}
          dropzoneOptions={{
            multiple: true,
            accept: {
              "image/*": [".svg", ".png", ".jpg", ".gif"],
            },
            onDrop: handleOnUpload,
            disabled: isUploading || isDisabled,
          }}
          showImage={false}
        />
      </SHStack>
      <GridImageGallery
        previewImagesDialogTitle={sectionName}
        images={
          toNativeValue(productDataValue as string, FieldTypeId.Slideshow) || []
        }
        handleOnDelete={handleOnDelete}
        isDisabled={isDisabled}
      />
    </SHStack>
  );
};

export default SeriesFeatureGallery;
