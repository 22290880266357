import { isEqual } from "lodash";

export const generateFeatureName = (
  featureName: string,
  featureDescription?: string,
) => {
  const lastSpaceIndex = featureName.trim().lastIndexOf(" ");

  if (isEqual(lastSpaceIndex, -1) || !featureDescription) {
    return {
      first: featureName,
      last: "",
    };
  }

  return {
    first: featureName.slice(0, lastSpaceIndex + 1),
    last: featureName.slice(lastSpaceIndex),
  };
};
