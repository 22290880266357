import { useTheme } from "@mui/material";
import {
  SHHtmlBlock,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ChangeEvent, useMemo } from "react";
import { HighlightType } from "@models/configuration";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { debounce, isEmpty, isEqual, isNil } from "lodash";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

export interface SeriesSectionTitleProps {
  sectionName?: string;
  selectedIndex: [number, number];
  seriesName?: string;
  isDisabled?: boolean;
  viewingGroupData?: ViewingGroupSelectionDTO[];
}

const SeriesSectionTitle = ({
  sectionName,
  selectedIndex = [0, 0],
  seriesName = "",
  isDisabled = false,
  viewingGroupData = [],
}: SeriesSectionTitleProps) => {
  const { control, setValue, getValues } = useFormContext();
  const { palette } = useTheme();

  const groupNameSeriesDataPath =
    `investmentProductSeriesData.${selectedIndex[0]}` as SeriesPath;
  const seriesViewingGroupDataPath =
    `${groupNameSeriesDataPath}.viewingGroupSelections` as SeriesPath;
  const featureNamePath =
    `investmentProductSeriesData.${selectedIndex[0]}.sections.0.features.0` as SeriesPath;
  const featureNameProductDataPath =
    `${featureNamePath}.productData` as SeriesPath;
  const featureProductDataModifiedPath =
    `${featureNamePath}.productData.isModified` as SeriesPath;
  const featureNameProductDataValuePath =
    `${featureNameProductDataPath}.value` as SeriesPath;
  const watchedValue = useWatch({
    control,
    name: featureNameProductDataValuePath,
  });
  const productDataValue = useMemo(
    () => getValues(featureNameProductDataValuePath),
      // eslint-disable-next-line
      [watchedValue, selectedIndex],
  );

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputValue = event.target.value;

    setValue(`${groupNameSeriesDataPath}.isModified`, true);
    setValue(`${groupNameSeriesDataPath}.name`, inputValue);
    setValue(`${featureNameProductDataPath}.value`, inputValue);
    setValue(
      `${featureNamePath}.productData.highlightType`,
      inputValue !== seriesName ? HighlightType.Edited : "",
    );
    setValue(
      `${featureNamePath}.productData.dataStatus`,
      isNil(inputValue) || isEmpty(inputValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(`${featureNamePath}.isModified`, inputValue !== seriesName);
    setValue(
      `${featureNamePath}.productData.isModified`,
      inputValue !== seriesName,
    );
  };
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    const isModified = !isEqual(viewingGroupData, newValue);

    setValue(`${groupNameSeriesDataPath}.isModified`, isModified);
    setValue(`${featureNamePath}.isModified`, isModified);
    setValue(featureProductDataModifiedPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const renderVisibilitySelections = () => {
    if (selectedIndex[1] === 0) {
      return (
        <Controller
          name={seriesViewingGroupDataPath}
          control={control}
          render={({ field: { ref, ...other } }) => (
            <SHStack direction="column" alignItems="flex-end">
              <SelectViewingGroups
                sx={{
                  width: "243px",
                  marginBottom: "5px",
                }}
                onChange={(value) => {
                  other.onChange(value);
                  debounceVisibilityChange(value);
                }}
                value={
                  (getValues(seriesViewingGroupDataPath) as
                    | ViewingGroupSelectionDTO[]
                    | null) || []
                }
                viewingGroupData={viewingGroupData}
                disabled={isDisabled}
              />
              <SHTypography
                disabled={isDisabled}
                variant="body4"
                fontWeight={500}
                p={0}
              >
                This series is visible to
              </SHTypography>
            </SHStack>
          )}
        />
      );
    }
  };

  return (
    <SHStack
      width={"100%"}
      direction={"row"}
      justifyContent={"space-between"}
      sx={{ pb: "25px" }}
    >
      <SHStack>
        <Controller
          name={groupNameSeriesDataPath as any}
          control={control}
          render={({ field: { ref, onChange, ...other } }) => {
            if (selectedIndex[1] === 0) {
              return (
                <SHTextField
                  {...other}
                  value={productDataValue}
                  required
                  disabled={isDisabled}
                  sx={{
                    paddingBottom: "5px",
                    "& .MuiInput-input": {
                      fontSize: "24px",
                      fontWeight: "600",
                      fontFamily: "Clash Display",
                      padding: "0px !important",
                      paddingTop: "2px !important",
                      width: "243px",
                    },
                  }}
                  placeholder={"Please enter detail"}
                  onChange={handleOnChange}
                />
              );
            }

            return (
              <SHTypography
                sx={{
                  fontSize: "24px",
                  fontWeight: "600",
                  fontFamily: "Clash Display",
                  padding: "0px !important",
                  paddingTop: "2px !important",
                  width: "270px",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                  wordBreak: "break-all",
                  marginBottom: "5px",
                }}
              >
                {productDataValue}
              </SHTypography>
            );
          }}
        />
        <SHHtmlBlock
          variant="body3"
          content={sectionName}
          color={palette.text.third}
        />
      </SHStack>
      <SHStack>{renderVisibilitySelections()}</SHStack>
    </SHStack>
  );
};

export default SeriesSectionTitle;
