import {
  SHBooleanField,
  SHHtmlBlock,
  SHLabel,
  SHRichTextEditor,
  SHStack,
} from "@components/design-systems";
import {
  useEditModelPaths,
  useInputDotIndicator,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import {
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { DEBOUNCE_DELAY } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import { RootState } from "@redux/store";
import { debounce, defaultTo, isEmpty, isEqual, toString } from "lodash";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { EditProductDefaults } from "../../../../../../constants";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import { useMemo } from "react";
import { InvestmentBannerStatus } from "@models/product/investment-product/enums/status";
interface ModelFeatureYesNoFieldProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationModelsFeature;
}

export const ModelFeatureYesNoField = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
}: ModelFeatureYesNoFieldProps) => {
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  const originalValue = feature.productData?.value;
  const originalAdditionalValue = feature.productData?.additionalValue;

  /** Hook */
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const { featureProductDataPath, featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );
  const watchedValue = useWatch({
    control,
    name: [featureProductDataPath.value],
  });
  const productDataValue = useMemo(() => {
    const value = getValues(featureProductDataPath.value);
    if (isEmpty(value)) return null;
    return isEqual(value, "true");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValue, featureIndex, selectedIndex, sectionIndex]);

  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );

  const displayAdditionalValueField = isEqual(productDataValue, true);
  /** State */

  /** Handler */
  const handleInputChange = (newValue: string | null) => {
    setValue(
      featureProductDataPath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(
      featurePath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(featurePath.isModified, !isEqual(newValue, originalValue));
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalValue),
    );
    const publishedValue = defaultTo(
      getValues(featureProductDataPath.publishedValue),
      "",
    );

    if (!isEqual(publishedValue, newValue)) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    handleUpdateSectionData();
  };

  const handleEditManagerAdditionalFieldChange = (newValue: string) => {
    setValue(
      featurePath.isModified,
      !isEqual(newValue, originalAdditionalValue),
    );
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalAdditionalValue),
    );

    const publishedAdditionalValue = defaultTo(
      getValues(featureProductDataPath.publishedAdditionalValue),
      "",
    );

    if (!isEqual(publishedAdditionalValue, newValue)) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    handleUpdateSectionData();
  };

  const debouncedHandleInputChange = debounce(
    handleInputChange,
    DEBOUNCE_DELAY,
  );

  const debouncedHandleEditAdditionalValueChange = debounce(
    handleEditManagerAdditionalFieldChange,
    EditProductDefaults.DebounceTime,
  );

  /** Side effect */

  return (
    <>
      <SHStack>
        <SHLabel
          dotGroupProps={{
            orangeDot: isShowOrangeDot,
            blueDot: isShowBlueDot,
            greenDot: feature.isBusinessMetric,
          }}
          label={feature.name}
        />
        <SHHtmlBlock variant="body3" content={feature.description} />
      </SHStack>

      <SHStack>
        <Controller
          control={control}
          name={featureProductDataPath["value"]}
          render={({ field: { ref, ...other } }) => (
            <SHBooleanField
              sx={{ width: "520px" }}
              disabled={isSaving || isPendingApproval}
              value={productDataValue}
              onChange={(_, value?: boolean | null) => {
                const newValue = toStringValue(value, FieldTypeId.YesNo);
                other.onChange(newValue);
                debouncedHandleInputChange(newValue);
              }}
            />
          )}
        />
        {displayAdditionalValueField && (
          <SHStack>
            <Controller
              name={featureProductDataPath.additionalValue as any}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHRichTextEditor
                  disabled={isSaving || isPendingApproval}
                  label={EditProductDefaults.AdditionalFieldTitle}
                  maxLength={MAX_LONG_TEXT_LENGTH}
                  height={165}
                  value={toString(
                    getValues(featureProductDataPath.additionalValue),
                  )}
                  onChange={(value) => {
                    other.onChange(value);
                    debouncedHandleEditAdditionalValueChange(value);
                  }}
                />
              )}
            />
          </SHStack>
        )}
      </SHStack>
    </>
  );
};
