import { UserPermission, UserType } from "@models/auth";
import { UserStatus } from "@models/users/enums/status";
import { SubscriptionBannerDTO } from "./subscriptionBanner";

// TODO: API team should be merge GetUserInfoDTO & CreateUserDTO
export class GetUserInfoDTO {
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  email: string = "";
  userType: UserType = UserType.SuperAdmin;
  avatarUrl: string = "";
  avatarS3Key: string = "";
  auth0Id: string = "";
  status?: UserStatus = undefined;
  permissions: UserPermission[] = [];
  productIds?: string[] = [];
  userMetadata?: UserMetaData = new UserMetaData();
  updatingFirm?: boolean = false;
  isNewLogin?: boolean = false;
  freeLicenceOnly?: boolean = false;
  isRestrictedUser?: boolean = false;
  subscriptionBanner?: SubscriptionBannerDTO[];
}

export class UserMetaData {
  is_first_login: boolean = false;
  is_profile_completed: boolean = false;
  supplier_id?: string = undefined;
  full_name?: string = undefined;
  adviser_firm_id?: string = undefined;
  is_system_admin?: boolean = false;
  is_adviser_admin?: boolean = false;
}

export interface CreateUserDTO {
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl?: string | null;
  avatarS3Key?: string | null;
  userType: UserType;
}

export interface CreateUserResponseDTO {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  userType: UserType;
  avatarUrl: string;
  avatarS3Key: string;
  auth0Id: string;
  status?: UserStatus;
  welcomeLink: string;
  permissions: string[];
}

export class UpdateUserDTO {
  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string | null;
  avatarS3Key?: string | null;
  userType?: UserType;
  userStatus?: UserStatus;
}

export class UserLOV {
  auth0Id: string = "";
  name: string = "";
  userStatus?: UserStatus;
  userType?: UserType;
  avatarUrl?: string | null;
}
