import { SHTextField } from "@components/design-systems";
import {
  InvestmentProductModelsDTO,
  ProductModelValueDTO,
} from "@models/product/investment-product/entities/model";
import { SxProps, Theme } from "@mui/material";
import {
  FeatureProductDataPath,
  updateCompositeJsonField,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { tryParseJson } from "@pages/suppliers/_id/util";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useMount } from "react-use";
interface TableDropDownProps {
  selectedIndex: [number, number];
  featureIndex: number;
  sectionIndex: number;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  featureProductDataPath: FeatureProductDataPath;
  productData: ProductModelValueDTO;
}
const TableTextField = ({
  selectedIndex,
  featureIndex,
  sectionIndex,
  onChange,
  disabled = false,
  sx,
  featureProductDataPath,
  productData,
}: TableDropDownProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const { control, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const watchedValue = useWatch({
    control,
    name: featureProductDataPath.value,
  }) as string | undefined;
  const productValue = useMemo(
    () => getValues(featureProductDataPath.value) as string | null,
    // eslint-disable-next-line
    [watchedValue, selectedIndex, featureIndex, sectionIndex],
  );
  const [currentValues, setCurrentValues] = useState<string | null>(
    productValue,
  );

  useMount(() => {
    setIsMounted(true);
  });
  useEffect(() => {
    if (isMounted) setCurrentValues(productValue);
    // eslint-disable-next-line
  }, [selectedIndex, isMounted]);

  const renderProductDataValue = () => {
    const headerId = productData.headerId;
    if (isEmpty(headerId)) return currentValues || "";
    if (isEmpty(currentValues)) return "";
    const productDataValues: ProductModelValueDTO[] | string = tryParseJson(
      currentValues || "",
    );
    if (typeof productDataValues !== "object") {
      return productDataValues;
    }
    const productDataValue = productDataValues.find(
      (value) => value.headerId === headerId,
    );
    return productDataValue?.value || "";
  };
  return (
    <>
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field }) => (
          <SHTextField
            sx={sx}
            maxLength={EditProductDefaults.MaxNumOfChars}
            disabled={disabled}
            placeholder="Please enter details"
            value={renderProductDataValue()}
            onChange={(e) => {
              onChange(e);
              if (!isEmpty(productData.headerId)) {
                const oldValue = getValues(
                  featureProductDataPath.value,
                ) as string;
                const updatedProductDataValues = updateCompositeJsonField(
                  e.target.value,
                  oldValue,
                  productData.headerId,
                );
                setCurrentValues(updatedProductDataValues);
              } else {
                setCurrentValues(e.target.value);
              }
            }}
          />
        )}
      />
    </>
  );
};

export default TableTextField;
