import { DependedFeature } from "@pages/suppliers/constants";

export class EditProductDefaults {
  static readonly DebounceTime = 300;
  static readonly MinDateTime = new Date("1/1/2000");
  static readonly MaxDateTime = new Date("12/31/2050");
  static readonly PlaceHolder = "Please enter detail";
  static readonly AdditionalFieldTitle = "Please enter details";
  static readonly MaxNumOfChars = 100;
  static readonly InvalidDate = "Invalid Date";
  static readonly InvalidDateFormatMessage = "Invalid date format";
  static readonly MaxPercentageValue = 100;
}

export enum SpecialViewingGroup {
  AllSuitabilityHubUsers = "All SuitabilityHub users",
  IMAPJudges = "IMAP Judges",
}

export class SpecialModelsSection {
  static readonly AssetAllocation = "Asset allocation";
}
export const Limit100PercentageFeatures = [
  DependedFeature.ACTIVE_SHARE.toString(),
  DependedFeature.TARGET_GROWTH_EXPOSURE.toString(),
];

export class ReportingAndCommunicationManagerFeatures {
  static readonly Feature2 = "Report content: Portfolio changes";
  static readonly Feature3 = "Report content: Portfolio change decisions";
  static readonly Feature4 = "Report content: Performance attribution";
  static readonly Feature5 =
    "Report content: Performance attribution commentary";
  static readonly Feature6 = "Report content: Top holdings";
  static readonly Feature7 = "Report content: Market overview (retrospective)";
  static readonly Feature8 = "Report content: Market outlook (forward looking)";

  static getFunctionNames(): string[] {
    return [
      this.Feature2,
      this.Feature3,
      this.Feature4,
      this.Feature5,
      this.Feature6,
      this.Feature7,
      this.Feature8,
    ];
  }
}
