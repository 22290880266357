import { SHButton, SHContainer, SHStack } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useIsNew } from "@hooks/useIsNew";
import { StepperContainer } from "@layouts/stepper/portal";
import { StepBadge } from "@layouts/stepper/step-badge";
import { Collapse, useTheme } from "@mui/material";

import { hexToRGBA } from "@utils";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { TransitionGroup } from "react-transition-group";

export interface StepCompProps {
  stepNumber?: number;
}
export interface StepCompRef {
  onChangeStep: (newStep?: Step, isNext?: boolean) => Promise<boolean>;
  onChangeOtherStep: () => Promise<boolean>;
  onSubmit: () => Promise<unknown>;
  onExit?: () => Promise<unknown>;
}
export interface Step {
  key?: string;
  label: string;
  component?: ForwardRefRenderFunction<StepCompRef, StepCompProps>;
  hidden?: boolean;
  unHighlighted?: boolean;
  subSteps?: Step[];
}
export interface StepperButtonState {
  isCanNext: boolean;
  isCanPrevious: boolean;
}
export interface StepperLayoutProps {
  steps: Step[];
  selectedStepIndex?: number;
  selectedSubStepIndex?: number;
  isUsePortal?: boolean;
  isLoading?: boolean;
  isCompleted?: boolean;
  isDirty?: boolean;
  isValid?: boolean;
  isSubmitting?: boolean;
  isShowFooterAction?: boolean;
  onChangeSelectedStepIndex?: (stepIndex: number) => void;
  onChangeSelectedSubStepIndex?: (subStepIndex: number) => void;
  onChangeButtonState?: (buttonState: StepperButtonState) => void;
  onChangeCurrentStep?: (key: string | undefined) => void;
}
export interface StepperLayoutRef {
  moveNextStep: () => Promise<boolean>;
  movePreviousStep: () => Promise<boolean>;
  onSubmit: () => Promise<unknown>;
  onExit?: () => Promise<unknown>;
}

const StepperLayoutComp: ForwardRefRenderFunction<
  StepperLayoutRef,
  StepperLayoutProps
> = (
  {
    steps = [],
    isUsePortal = false,
    isLoading,
    isCompleted,
    isDirty,
    isValid,
    isSubmitting,
    isShowFooterAction,
    selectedStepIndex: selectedStepIndexProps,
    selectedSubStepIndex: selectedSubStepIndexProps,
    onChangeSelectedStepIndex,
    onChangeSelectedSubStepIndex,
    onChangeButtonState,
    onChangeCurrentStep,
  },
  ref,
) => {
  const isNew = useIsNew();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const stepCompRef = useRef<StepCompRef | null>(null);
  const [selectedStepIndexState, setSelectedStepIndexState] = useState(0);
  const [selectedSubStepIndexState, setSelectedSubStepIndexState] = useState(0);

  const selectedStepIndex =
    selectedStepIndexProps !== undefined
      ? selectedStepIndexProps
      : selectedStepIndexState;

  const selectedSubStepIndex =
    selectedSubStepIndexProps !== undefined
      ? selectedSubStepIndexProps
      : selectedSubStepIndexState;

  const visibleSteps = steps
    .filter((step) => !step.hidden)
    .map((step) => ({
      ...step,
      subSteps: step.subSteps?.filter((subStep) => !subStep.hidden),
    }));
  const selectedStep: Step | undefined = visibleSteps?.[selectedStepIndex];
  const selectedSubStep = selectedStep?.subSteps?.[selectedSubStepIndex];
  const isCanNext =
    selectedStepIndex + 1 < visibleSteps.length ||
    (selectedStep?.subSteps?.length
      ? selectedSubStepIndex + 1 < selectedStep.subSteps.length
      : false);
  const isCanPrevious =
    selectedStepIndex > 0 ||
    (selectedStep?.subSteps?.length ? selectedSubStepIndex > 0 : false);

  const StepComponent = useMemo(() => {
    const selectedStepComp = selectedStep?.component;
    const selectedSubStepComp = selectedSubStep?.component;
    return selectedStepComp
      ? forwardRef(selectedStepComp)
      : selectedSubStepComp
      ? forwardRef(selectedSubStepComp)
      : undefined;
    // eslint-disable-next-line
  }, [
    selectedStep?.key,
    selectedStep?.label,
    selectedSubStep?.key,
    selectedSubStep?.label,
  ]);

  useImperativeHandle(ref, () => ({
    moveNextStep: async () => {
      if (selectedStep?.subSteps?.length) {
        const newSubStep = selectedStep?.subSteps?.[selectedSubStepIndex + 1];
        const haveNextSubStep =
          selectedSubStepIndex + 1 < selectedStep?.subSteps?.length;
        const canNextSubStep =
          haveNextSubStep &&
          (!stepCompRef.current?.onChangeStep ||
            (await stepCompRef.current.onChangeStep(newSubStep)));
        if (canNextSubStep) {
          const newSubStepIndex = selectedSubStepIndex + 1;
          handleOnChangeSubStep(newSubStepIndex);
          return true;
        }
        if (haveNextSubStep) return false;
      }

      const newStep = visibleSteps[selectedStepIndex + 1];
      const canNextStep =
        selectedStepIndex + 1 < visibleSteps.length &&
        (!stepCompRef.current?.onChangeStep ||
          (await stepCompRef.current.onChangeStep(newStep, true)));

      if (canNextStep) {
        const newSubStepIndex = 0;
        const newStepIndex = selectedStepIndex + 1;
        handleOnChangeStep(newStepIndex);
        handleOnChangeSubStep(newSubStepIndex);
      }
      return canNextStep;
    },
    movePreviousStep: async () => {
      if (selectedStep?.subSteps?.length) {
        const newSubStep = selectedStep?.subSteps?.[selectedSubStepIndex - 1];
        const canPreviousSubStep =
          selectedSubStepIndex > 0 &&
          (!stepCompRef.current?.onChangeStep ||
            (await stepCompRef.current.onChangeStep(newSubStep)));
        if (canPreviousSubStep) {
          const newSubStepIndex = selectedSubStepIndex - 1;
          handleOnChangeSubStep(newSubStepIndex);
          return canPreviousSubStep;
        }
      }

      const newStep = visibleSteps[selectedStepIndex - 1];
      const canPreviousStep =
        selectedStepIndex > 0 &&
        (!stepCompRef.current?.onChangeStep ||
          (await stepCompRef.current.onChangeStep(newStep)));

      if (canPreviousStep) {
        const newStepIndex = selectedStepIndex - 1;
        const newSubStepIndex = newStep?.subSteps?.length
          ? newStep?.subSteps?.length - 1
          : 0;
        handleOnChangeStep(newStepIndex);
        handleOnChangeSubStep(newSubStepIndex);
      }
      return canPreviousStep;
    },
    onSubmit: async () => {
      if (stepCompRef.current) return await stepCompRef.current.onSubmit();
    },
    onExit: async () => {
      if (stepCompRef.current && stepCompRef.current.onExit !== undefined)
        return await stepCompRef.current.onExit();
    },
  }));

  const handleOnChangeSubStep = (index: number) => {
    setSelectedSubStepIndexState(index);
    if (onChangeSelectedSubStepIndex) {
      onChangeSelectedSubStepIndex(index);
    }
  };

  const handleOnChangeStep = (index: number) => {
    setSelectedStepIndexState(index);
    if (onChangeSelectedStepIndex) {
      onChangeSelectedStepIndex(index);
    }
  };

  useEffect(() => {
    if (!onChangeCurrentStep) return;
    onChangeCurrentStep(selectedStep?.key);
  }, [selectedStep?.key, onChangeCurrentStep]);

  useEffect(() => {
    if (!onChangeButtonState) return;
    onChangeButtonState({
      isCanNext,
      isCanPrevious,
    });
    // eslint-disable-next-line
  }, [isCanNext, isCanPrevious]);

  useEffect(() => {
    if (!visibleSteps?.length) {
      handleOnChangeStep(0);
      return;
    }
    if (selectedStepIndex + 1 > visibleSteps?.length)
      handleOnChangeStep(visibleSteps?.length - 1);
    // eslint-disable-next-line
  }, [visibleSteps?.length]);

  return (
    <>
      <StepperContainer isUsePortal={isUsePortal}>
        <SHStack
          sx={{
            backgroundColor: hexToRGBA(palette.background.default, 0.5),
            backdropFilter: "blur(30px)",
            borderBottom: `1px solid ${palette.secondary[100]}`,
            p: "10px",
            zIndex: 1100,
            minHeight: "52px",
          }}
        >
          <TransitionGroup
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "20px",
              flexWrap: "wrap",
            }}
          >
            {visibleSteps?.map((step, index) => (
              <Collapse key={step.key ?? step.label} orientation="horizontal">
                <StepBadge
                  isLoading={isLoading}
                  label={step.label}
                  unHighlighted={step.unHighlighted}
                  selected={index === selectedStepIndex}
                  stepNumber={index + 1}
                  stepsLength={visibleSteps.length}
                  onClick={async () => {
                    if (stepCompRef?.current) {
                      const allowChangeStep =
                        await stepCompRef.current.onChangeOtherStep();
                      if (allowChangeStep) {
                        handleOnChangeStep(index);
                        if (!isCompleted) handleOnChangeSubStep(1);
                      }
                    }
                  }}
                  isDisabled={
                    isDirty ||
                    step.unHighlighted ||
                    (index > selectedStepIndex && !isCompleted) ||
                    (index === selectedStepIndex && !isCompleted)
                  }
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </SHStack>
      </StepperContainer>
      {StepComponent && !isLoading && selectedStepIndex >= 0 && (
        <StepComponent ref={stepCompRef} stepNumber={selectedStepIndex + 1} />
      )}
      {isShowFooterAction && (
        <SHContainer>
          <SHStack
            direction="row"
            justifyContent="flex-end"
            alignItems={"center"}
            spacing={"15px"}
            marginBottom={"25px"}
            marginRight={"-25px"}
          >
            <SHButton
              variant="outlined"
              size="extraMedium"
              disabled={isSubmitting}
              onClick={() => {
                stepCompRef?.current &&
                  stepCompRef.current.onExit &&
                  stepCompRef.current.onExit();
                navigate(PageRoutes.managedAccounts);
              }}
            >
              Exit
            </SHButton>
            <SHButton
              variant="outlined"
              size="extraMedium"
              disabled={!isValid || isSubmitting || (!isNew && !isDirty)}
              onClick={async () => {
                stepCompRef?.current &&
                  stepCompRef.current.onSubmit &&
                  stepCompRef.current.onSubmit();
              }}
            >
              Save
            </SHButton>
            <SHButton
              variant="contained"
              size="extraMedium"
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              onClick={async () => {
                if (stepCompRef?.current) {
                  const allowChangeStep =
                    await stepCompRef.current.onChangeStep();
                  if (allowChangeStep) {
                    handleOnChangeStep(1);
                  }
                }
              }}
            >
              Next
            </SHButton>
          </SHStack>
        </SHContainer>
      )}
    </>
  );
};
export const StepperLayout = forwardRef(StepperLayoutComp);
