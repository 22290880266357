import { SHBox } from "@components/design-systems";
import { SHNumberTextField } from "@components/design-systems/sh-number-text-field";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { getFeatureProductDataPaths } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

interface DependFieldProps {
  selectedIndex: [number, number];
  featureIndex: number;
  sectionIndex: number;
}
const DependField = ({
  selectedIndex,
  featureIndex,
  sectionIndex,
}: DependFieldProps) => {
  const { control, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );

  const watchedValue = useWatch({
    control,
    name: [
      featureProductDataPath.value,
      featureProductDataPath.highlightType,
      featureProductDataPath.dataStatus,
    ],
  });
  const productDataValue = useMemo(
    () => getValues(featureProductDataPath.value) as string | null,
    // eslint-disable-next-line
    [watchedValue, selectedIndex],
  );

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field }) => (
        <SHBox sx={{ width: "50%" }}>
          <SHNumberTextField
            sx={{
              "& .MuiInput-root > input": {
                padding: "10px 0px 8px 0px",
              },
            }}
            disabled
            placeholder="Enter details in above cell"
            suffix="%"
            decimalScale={2}
            fixedDecimalScale
            valueIsNumericString
            value={productDataValue}
            onChange={(value) => {}}
          />
        </SHBox>
      )}
    />
  );
};

export default DependField;
