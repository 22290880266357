import { SHDialog } from "@components/design-systems/sh-dialog";
import mainBgImage from "@assets/images/background/main.svg";
import { CloseButton } from "@components/buttons/close";
import { SHTypography, SHStack, SHButton } from "@components/design-systems";
import { FooterSVG } from "@components/svgs";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";

export interface MAFeeDisclaimerDialogProps {
  onClose?: () => void;
}

export const MAFeeDisclaimerDialog = ({
  onClose,
}: MAFeeDisclaimerDialogProps) => {
  const { palette } = useTheme();

  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundImage: `url(${mainBgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: { xs: "100%", md: 700 },
          border: 0,
        },
      }}
    >
      <DialogTitle
        display={"flex"}
        flexDirection="row"
        alignItems="stretch"
        justifyContent="space-between"
        padding={"0px !important"}
        borderBottom={`1px solid ${palette.common.black}`}
      >
        <SHTypography
          variant="h2"
          fontWeight={600}
          fontSize={20}
          padding={"10px 40px !important"}
          borderRight={`1px solid ${palette.common.black}`}
          textAlign={"center"}
          textTransform={"uppercase"}
        >
          Fee calculator
        </SHTypography>
        <SHStack padding={"21px 31px"}>
          <CloseButton width="8.75px" height="8.75px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px !important", overflow: "auto" }}>
        <SHStack textAlign={"justify"} marginY={"44px"} ml={"40px"} mr={"20px"}>
          <SHTypography variant="subtitle2" textTransform={"uppercase"}>
            Fee analysis disclaimer
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            This information is provided for general use only. SuitabilityHub
            displays information sourced from our users and other third parties,
            and you must make your own enquiries as to the accuracy and
            completeness of this information. No representation or warranty
            (either express or implied) is given as to the truth, accuracy or
            completeness of the information.
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Results generated by SuitabilityHub’s calculator are illustrative
            only and rely on information that may change over time. These
            results should not be relied upon for the purpose of making a
            decision in relation to a particular financial product, platform or
            provider. In displaying these results, SuitabilityHub does not
            provide financial advice or make recommendations about the
            suitability of any financial product, platform, or provider.
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Fees displayed represent estimated costs for holding each model for
            12 months based on the stated Product Disclosure Statement (PDS)
            date. Past fees are not an indicator of future fees. Fee breakdowns
            are primarily based on historical allocations and performance of the
            model and are likely to vary in future periods. Fee data is sourced
            directly from platform providers, and subject to the provider’s
            individual reporting methodologies. In some cases, platforms may
            combine or omit specific fees in their reporting.
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Any result provided by our calculator is not an offer, and financial
            product providers will undertake their own fee calculations. Unless
            otherwise stated, all amounts are in Australian dollars.
          </SHTypography>
        </SHStack>
        <FooterSVG style={{ marginBottom: "-5px" }} />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          borderTop: `1px solid ${palette.common.black}`,
          height: 110,
          margin: 0,
        }}
      >
        <SHButton
          color="primary"
          variant="contained"
          onClick={onClose}
          size="large"
          sx={{ width: "260px" }}
        >
          Close
        </SHButton>
      </DialogActions>
    </SHDialog>
  );
};
