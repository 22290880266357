import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHHtmlBlock,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { CheckSquareSVG, CrossSquareSVG } from "@components/svgs";
import EyeIconTooltip from "@components/svgs/eye-tooltip";
import { DateFormat, FullDateFormat } from "@constants/format";
import {
  ConfigurationFeature,
  ConfigurationSection,
  FieldTypeId,
  SectionDisplayTypeId,
} from "@models/configuration";
import { Card, useTheme } from "@mui/material";
import { checkStringIsNumber } from "@pages/suppliers/_id/util";
import { VisibilityMessage } from "@pages/suppliers/constants";
import { generateFeatureName } from "@utils/text-format";
import { format, parse } from "date-fns";
import { isEmpty } from "lodash";
import { NumericFormat } from "react-number-format";
export interface SidePanelSectionProps {
  section?: ConfigurationSection;
}

export default function SidePanelSection({ section }: SidePanelSectionProps) {
  const { palette } = useTheme();

  const displayFeatureValue = (feature: ConfigurationFeature) => {
    const value = feature.productData?.value;
    const isViewRestricted = feature.isViewRestricted;

    if (isViewRestricted) {
      return (
        <SHTypography variant="body4" colorVariant="disabled">
          {VisibilityMessage.FEATURE_LEVEL}
        </SHTypography>
      );
    }

    if (feature.fieldType?.id === FieldTypeId.YesNo) {
      switch (value) {
        case "true":
          return <CheckSquareSVG />;
        case "false":
          return <CrossSquareSVG />;
      }
    }

    if (!value) {
      return (
        <SHTypography
          variant="body2"
          colorVariant="fourth"
          sx={{
            minWidth: "54px",
          }}
        >
          No data
        </SHTypography>
      );
    }
    if (feature.fieldType?.id === FieldTypeId.DateTime) {
      const dateValue = parse(value, FullDateFormat, new Date());
      return (
        <SHTypography
          variant="body3"
          colorVariant="fourth"
          textAlign={
            section?.sectionDisplayType?.id ===
            SectionDisplayTypeId.SidePanelRow
              ? "left"
              : "right"
          }
        >
          {format(dateValue, DateFormat)}
        </SHTypography>
      );
    }

    /** Percentage */
    if (
      feature.fieldType?.id === FieldTypeId.PercentageText ||
      feature.fieldType?.id === FieldTypeId.Percentage
    ) {
      const isNumber = checkStringIsNumber(value);
      if (!isNumber)
        return (
          <SHTypography
            textAlign={
              section?.sectionDisplayType?.id ===
              SectionDisplayTypeId.SidePanelRow
                ? "left"
                : "right"
            }
            colorVariant="fourth"
            variant="subtitle2"
          >
            {value}
          </SHTypography>
        );
      return (
        <SHTypography variant="subtitle2" colorVariant="fourth">
          <NumericFormat
            valueIsNumericString
            displayType="text"
            value={value}
            suffix="%"
          />
        </SHTypography>
      );
    }
    /** Number */
    if (
      feature.fieldType?.id === FieldTypeId.NumberText ||
      feature.fieldType?.id === FieldTypeId.Number
    ) {
      const isNumber = checkStringIsNumber(value);
      if (!isNumber)
        return (
          <SHTypography
            textAlign={
              section?.sectionDisplayType?.id ===
              SectionDisplayTypeId.SidePanelRow
                ? "left"
                : "right"
            }
            colorVariant="disabled"
            variant="subtitle2"
          >
            {value}
          </SHTypography>
        );
      return (
        <SHTypography variant="subtitle2" colorVariant="fourth">
          <NumericFormat
            valueIsNumericString
            displayType="text"
            value={value}
            thousandSeparator=","
          />
        </SHTypography>
      );
    }
    /** Currency */
    if (
      feature.fieldType?.id === FieldTypeId.Currency ||
      feature.fieldType?.id === FieldTypeId.CurrencyText
    ) {
      const isNumber = checkStringIsNumber(value);
      if (!isNumber)
        return (
          <SHTypography
            textAlign={
              section?.sectionDisplayType?.id ===
              SectionDisplayTypeId.SidePanelRow
                ? "left"
                : "right"
            }
            colorVariant="fourth"
            variant="subtitle2"
          >
            {value}
          </SHTypography>
        );
      return (
        <SHTypography variant="subtitle2" colorVariant="fourth">
          <NumericFormat
            valueIsNumericString
            displayType="text"
            value={value}
            thousandSeparator=","
            prefix="$"
          />
        </SHTypography>
      );
    }
    /** Text Short */

    if (feature.fieldType?.id === FieldTypeId.TextShort) {
      return (
        <SHHtmlBlock
          textAlign={
            section?.sectionDisplayType?.id ===
            SectionDisplayTypeId.SidePanelRow
              ? "left"
              : "right"
          }
          variant="subtitle2"
          content={value}
          color={palette.text.fourth}
        />
      );
    }

    /** Text Long */
    return (
      <SHHtmlBlock
        textAlign={
          section?.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow
            ? "left"
            : "right"
        }
        variant="subtitle2"
        content={value}
        color={palette.text.fourth}
      />
    );
  };

  return (
    <Card
      variant="outlined"
      sx={{
        minWidth: 351,
        padding: "15px",
        border: `1px solid #${palette.secondary[100]}`,
        borderRadius: 0,
      }}
    >
      <SHStack>
        <SHStack direction="row" spacing={0.5}>
          <SHTypography variant="body1">{section?.name}</SHTypography>
          {section?.description && (
            <InformationButton content={section.description} />
          )}
        </SHStack>
        <SHStack
          spacing={
            section?.sectionDisplayType?.id ===
            SectionDisplayTypeId.SidePanelRow
              ? 2
              : 0
          }
        >
          {section?.features?.map((feature, index) => {
            const featureName = generateFeatureName(
              feature.name,
              feature.description,
            );
            const maxWidth =
              section.sectionDisplayType?.id ===
              SectionDisplayTypeId.SidePanelRow
                ? "100%"
                : feature?.hasCustomVisibility && !feature.isViewRestricted
                ? "70%"
                : "75%";
            return (
              <SHStack
                key={index}
                direction={
                  section.sectionDisplayType?.id ===
                  SectionDisplayTypeId.SidePanelRow
                    ? "column"
                    : "row"
                }
                spacing={
                  section.sectionDisplayType?.id ===
                  SectionDisplayTypeId.SidePanelRow
                    ? 0
                    : 2
                }
                justifyContent="space-between"
                sx={{
                  wordBreak: "break-word",
                  pt: "5px",
                  width: "100%",
                }}
              >
                <SHTypography
                  variant="body2"
                  colorVariant="fourth"
                  textAlign="left"
                  sx={{
                    maxWidth: maxWidth,
                    flex: "1 1 auto",
                    minWidth: "30%",
                  }}
                >
                  {featureName.first}
                  <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                    {featureName.last}
                    {!isEmpty(feature?.description) && (
                      <InformationButton
                        content={feature.description}
                        margin={"0px 0px 2px 4px !important"}
                        textColor={palette.text.fourth}
                      />
                    )}
                    <EyeIconTooltip
                      tooltipProps={{
                        title: VisibilityMessage.HAS_CUSTOM_VISIBILITY,
                      }}
                      iconProps={{
                        style: {
                          margin: " 0px 0px -3px 10px",
                        },
                      }}
                      isVisible={
                        feature?.hasCustomVisibility &&
                        !feature.isViewRestricted
                      }
                    />
                  </SHBox>
                </SHTypography>

                <SHBox
                  textAlign={
                    section?.sectionDisplayType?.id ===
                    SectionDisplayTypeId.SidePanelRow
                      ? "left"
                      : "right"
                  }
                  sx={{
                    flex: "1 1 auto",
                  }}
                >
                  {displayFeatureValue(feature)}
                </SHBox>
              </SHStack>
            );
          })}
        </SHStack>
      </SHStack>
    </Card>
  );
}
