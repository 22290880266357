import { DisclaimerAssumptionsButton } from "@components/buttons/disclaimer-assumptions";
import { SHTypography } from "@components/design-systems";
import ModelPerformanceDisclaimerDialog from "@components/dialogs/model-performance-disclaimer";
import React from "react";
import { useToggle } from "react-use";

export const PerformanceDisclaimerText = () => {
  const [showDisclaimerDialog, toggleShowDisclaimerDialog] = useToggle(false);
  return (
    <>
      <SHTypography variant="body4" py="10px" fontSize={"10px"} width={"100%"}>
        By using the portfolio returns and risk measures information, you
        confirm that you have read and accepted the{" "}
        <DisclaimerAssumptionsButton
          onClick={() => toggleShowDisclaimerDialog(true)}
        />
      </SHTypography>
      {showDisclaimerDialog && (
        <ModelPerformanceDisclaimerDialog
          onClose={() => toggleShowDisclaimerDialog(false)}
        />
      )}
    </>
  );
};
