import {
  SHButton,
  SHDataGrid,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { DeleteSVG, EditSVG } from "@components/svgs";
import { APIExtRoutes, DELAY_TIME } from "@constants";
import { DateTimeFormat } from "@constants/format";
import { ODataResult } from "@models/core";
import { ProductStatus } from "@models/product/enums/status";
import { ViewingGroupDTO } from "@models/product/investment-product/entities/viewingGroup";
import { Tooltip, tooltipClasses, useTheme } from "@mui/material";
import { ArchiveConfirmDialog } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/archive-viewing-group-dialog";
import ViewingGroupDialog from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/viewing-group-dialog";
import { DialogTitles } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/config";
import { customViewingGroupSort } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/util";

import { createColumnHelper } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format, isValid } from "date-fns";
import { toString } from "lodash";
import { useMemo, useState } from "react";
import { generatePath, useParams } from "react-router";
import { useToggle } from "react-use";
const nameOf = nameOfFactory<ViewingGroupDTO>();
const columnHelper = createColumnHelper<ViewingGroupDTO>();

const pickColumns: (keyof ViewingGroupDTO)[] = [
  "id",
  "name",
  "createdDate",
  "lastModifiedDate",
  "status",
];

const ViewingGroupTable = () => {
  const { supplierId } = useParams();
  const { palette } = useTheme();
  const [openEditDialog, toggleOpenEditDialog] = useToggle(false);
  const [openDeleteDialog, toggleOpenDeleteDialog] = useToggle(false);
  const [selectedGroup, setSelectedGroup] = useState<ViewingGroupDTO | null>(
    null,
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Group name",
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
        enableColumnFilter: false,
      }),

      columnHelper.accessor("lastModifiedDate", {
        header: "Last modified",
        cell: (props) => {
          const date = toString(props.cell.getValue());
          if (!isValid(new Date(date))) return "";
          return date ? format(new Date(date), `${DateTimeFormat}`) : "";
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => (
          <StatusBadge status={props.cell.getValue() as ProductStatus} />
        ),
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => {
          return (
            <SHStack spacing={2} direction="row">
              <SHButton
                disabled
                onClick={() => {
                  setSelectedGroup(props.cell.getValue());
                  toggleOpenEditDialog();
                }}
                startIcon={<EditSVG color={palette.common.white} />}
                variant="contained"
              >
                Edit
              </SHButton>
              <Tooltip
                title="Archived"
                enterDelay={DELAY_TIME}
                enterNextDelay={100}
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                        {
                          marginTop: "6px",
                        },
                    },
                  },
                }}
              >
                {/* TODO: split to seperate component to control disable state */}
                <SHButton
                  disabled
                  onClick={() => {
                    toggleOpenDeleteDialog();
                  }}
                  sx={{
                    padding: "5px",
                    "&:active": {
                      "&>svg>path": {
                        fill: palette.common.white,
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <DeleteSVG color={palette.grey[500]} />
                </SHButton>
              </Tooltip>
            </SHStack>
          );
        },
        meta: {
          sx: {
            width: "185px",
          },
        },
      }),
    ],
    // eslint-disable-next-line
    [],
  );

  return (
    <>
      <SHDataGrid
        searchBoxProps={{
          placeholder: "Search viewing groups",
        }}
        state={{
          ...DefaultDataState,
          sorting: [{ id: nameOf("name"), desc: false }],
        }}
        columns={columns as any}
        odata={{
          pickColumns,
          url: generatePath(APIExtRoutes.odataViewingGroups, {
            supplierId,
          }),
          globalFilterColumns: [nameOf("name")],
          onDataReceived: (state, data: ODataResult<ViewingGroupDTO>) => {
            const sorted = customViewingGroupSort(data.value);
            return {
              ...data,
              value: sorted,
            };
          },
        }}
        emptyMessage="No data"
      />
      <ViewingGroupDialog
        isOpen={openEditDialog}
        onClose={toggleOpenEditDialog}
        dialogTitle={DialogTitles.EDIT_VIEWING_GROUP}
        initialData={selectedGroup}
      />
      <ArchiveConfirmDialog
        isLoading={false}
        open={openDeleteDialog}
        onClose={toggleOpenDeleteDialog}
        onOk={() => {}}
      />
    </>
  );
};

export default ViewingGroupTable;
