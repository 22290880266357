import { SHStack } from "@components/design-systems";

export interface FormFieldContainerProps {
  children?: React.ReactNode;
}
export const FormFieldContainer = ({ children }: FormFieldContainerProps) => {
  return (
    <SHStack
      flexDirection="row"
      flexWrap={{ sm: "wrap", lg: "nowrap" }}
      gap={{ sm: 2, lg: 4 }}
      justifyContent={"space-between"}
    >
      {children}
    </SHStack>
  );
};
