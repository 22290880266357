import { DeleteButton } from "@components/buttons/delete";
import {
  SHAvatarProps,
  SHLabelProps,
  SHUploadImage,
  SHUploadImageProps,
} from "@components/design-systems";
import { SHUploadFileProps } from "@components/design-systems/sh-upload-file";
import { useNotification } from "@hooks/useNotification";
import { ImageField } from "@models/core";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import { renameFile } from "@utils/data-type/file";
import { isEmpty } from "lodash";
import { useState } from "react";
import { FileRejection } from "react-dropzone";

export interface UploadFieldProps {
  isEditMode?: boolean;
  value: ImageField | null;
  onChange: (value: ImageField | null) => void;
}
export const UploadField = ({
  isEditMode,
  disabled,
  value,
  onChange,
  error,
  helperText,

  ...others
}: SHAvatarProps &
  SHUploadFileProps &
  SHUploadImageProps &
  UploadFieldProps &
  SHLabelProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const { notify } = useNotification();

  const handleOnUpload = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ) => {
    setUploadMessage(
      !isEmpty(fileRejections)
        ? "Unsupported file format. Please upload another file."
        : null,
    );
    if (isEmpty(acceptedFiles)) return;
    const file = new FormData();
    file.append("file", renameFile(acceptedFiles[0]));
    setIsUploading(true);
    const { data, message } = await uploadAvatar(file);
    if (data) {
      onChange({
        key: data?.key,
        url: data?.url,
      });
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsUploading(false);
  };

  const handleOnDelete = async () => {
    const s3Key = value?.key;
    if (!s3Key) return;
    setIsDeleting(true);
    const { isSuccess, message } = await deleteAvatar(s3Key);
    setIsDeleting(false);
    if (isSuccess) {
      onChange(null);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };

  return (
    <SHUploadImage
      objectFit="contain"
      isUploading={isUploading}
      disabled={disabled}
      src={value?.url}
      isCircular={false}
      actionIcon={
        !isEmpty(value) &&
        isEditMode && (
          <DeleteButton
            disabled={disabled}
            isLoading={isDeleting}
            onClick={handleOnDelete}
          />
        )
      }
      error={!!error || !!uploadMessage}
      helperText={helperText ?? uploadMessage}
      dropzoneOptions={{
        multiple: false,
        accept: {
          "image/*": [".svg", ".png", ".jpg", ".gif"],
        },
        onDrop: handleOnUpload,
        disabled: isUploading || disabled,
      }}
      {...others}
    />
  );
};
