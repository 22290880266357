import {
  SHButton,
  SHDotGroup,
  SHDotGroupProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { PlusSVG } from "@components/svgs";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import {
  InvestmentProductConfigurationModel,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
} from "@models/product/investment-product/enums/status";
import { useTheme } from "@mui/material";
import {
  getFeaturePaths,
  getFeatureProductDataPaths,
  getModelPath,
  getSectionPath,
  getSeriesPath,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { updateInvestmentProductModelsData } from "@redux/slices/product/investment-product";
import { RootState, useAppDispatch } from "@redux/store";
import { produce } from "immer";
import { isEqual } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
interface MenuItemProps {
  sectionIndex: number;
  section: InvestmentProductModelsDTO;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  onNewButtonClick?: () => void;
}
const MenuItem = ({
  sectionIndex,
  section,
  dotGroupProps,
  onNewButtonClick,
}: MenuItemProps) => {
  const seriesPath = getSeriesPath(sectionIndex);
  //state
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  const [isCreatingModel, setIsCreatingModel] = useState(false);

  // Hooks
  const { createInvestmentProductConfigurationModel } = useInvestmentProduct();
  const { investmentProductId } = useParams();
  const dispatch = useAppDispatch();

  const { palette } = useTheme();
  const { control, setValue, getValues, reset } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const modelsData = useWatch({
    control,
    name: seriesPath.models,
  }) as InvestmentProductConfigurationModel[] | undefined;
  const isAnyModelEdited = modelsData?.some(
    (model) => model.highlightType === HighlightType.Edited,
  );
  const isAnyModelModified = modelsData?.some((model) => model.isModified);
  const isAnyModelMissingData = modelsData?.some(
    (model) => model.dataStatus === InvestmentDataStatus.MissingData,
  );
  const [dataStatus, highlightType] = useWatch({
    control,
    name: [seriesPath.dataStatus, seriesPath.highlightType],
  }) as [InvestmentDataStatus | undefined, HighlightType | undefined];
  // Handlers
  const handleCreateConfigurationModel = async (seriesId?: string) => {
    if (!investmentProductId || !seriesId) return;

    setIsCreatingModel(true);

    try {
      const data = await createInvestmentProductConfigurationModel(
        seriesId,
        investmentProductId,
      );
      if (!data) {
        setIsCreatingModel(false);
        return;
      }

      const currentModels =
        (getValues(seriesPath.models) as
          | InvestmentProductConfigurationModel[]
          | undefined) ?? [];

      // Update order of new model
      const updatedNewModelOrder = produce(data, (draft) => {
        draft.order = currentModels.length + 1;
      });

      //add new model into current models

      const updatedModels = produce(currentModels, (draft) => {
        draft.push(updatedNewModelOrder);
      });
      // Update form data
      setValue(seriesPath.models, updatedModels);

      const lastModelIndex = updatedModels.length - 1;

      const newModelsTitlePath = getFeatureProductDataPaths(
        [sectionIndex, lastModelIndex],
        0,
        0,
      );
      const newModelsFeaturePath = getFeaturePaths(
        [sectionIndex, lastModelIndex],
        0,
        0,
      );
      const sectionPath = getSectionPath([sectionIndex, lastModelIndex], 0);
      const modelPath = getModelPath([sectionIndex, lastModelIndex]);

      // Update meta data, ensure draft button is active
      setValue(newModelsTitlePath.value, data.name);
      setValue(newModelsTitlePath.isModified, true);
      setValue(newModelsFeaturePath.isModified, true);
      setValue(sectionPath.isModified, true);
      setValue(modelPath.isModified, true);

      const updatedForm = getValues("investmentProductModels");

      // Dispatch updated data
      dispatch(updateInvestmentProductModelsData(updatedForm));

      // Reset form
      reset({
        investmentProductModels: updatedForm,
      });
    } catch (error) {
      console.error("Error creating configuration model:", error);
    } finally {
      setIsCreatingModel(false);
    }
  };
  const handleChangeHighlighType = () => {
    setValue(
      seriesPath.highlightType,
      isAnyModelEdited ? HighlightType.Edited : null,
    );
  };
  const handleChangeIsModified = () => {
    setValue(seriesPath.isModified, isAnyModelModified);
  };
  const handleChangeMissingData = () => {
    setValue(
      seriesPath.dataStatus,
      isAnyModelMissingData
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
  };
  // Effects
  useEffect(() => {
    handleChangeHighlighType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyModelEdited]);
  useEffect(() => {
    handleChangeIsModified();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyModelModified]);
  useEffect(() => {
    handleChangeMissingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnyModelMissingData]);

  const isDisable = useMemo(() => {
    return isCreatingModel || isSaving || isPendingApproval;
  }, [isCreatingModel, isSaving, isPendingApproval]);

  return (
    <SHStack
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <SHStack direction="row" spacing={0.5} alignItems="start">
        <SHTypography variant="subtitle1">{section.name}</SHTypography>
        <SHDotGroup
          blueDot={
            dotGroupProps?.blueDot &&
            dataStatus === InvestmentDataStatus.MissingData
          }
          orangeDot={
            dotGroupProps?.orangeDot && highlightType === HighlightType.Edited
          }
          paddingTop={"5px"}
        />
      </SHStack>
      <SHButton
        isLoading={isCreatingModel}
        disabled={isDisable}
        variant="text"
        color="primary"
        size="extraMedium"
        sx={{ fontWeight: 600 }}
        onClick={async (e) => {
          e.stopPropagation();
          if (onNewButtonClick) onNewButtonClick();
          await handleCreateConfigurationModel(section.id);
        }}
        startIcon={
          <PlusSVG
            color={isDisable ? palette.text.disabled : palette.primary.main}
            width={16}
            height={16}
          />
        }
      >
        New
      </SHButton>
    </SHStack>
  );
};

export default MenuItem;
