import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHDotGroup,
  SHLabel,
  SHRichTextEditor,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
  toEditManagerNativeValue,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce, isEqual } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { generateFeatureName } from "@utils/text-format";

export const EditManagerTextLongInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const postfixLabel = useMemo(() => {
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description]);

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  const renderControllerLabel = () => {
    const allowedVisibility = !!featureData?.allowedVisibilitySetting;
    const { last, first } = generateFeatureName(
      featureData?.name ?? "",
      featureData.description,
    );

    const dotProps = {
      orangeDot: isShowOrangeDot,
      blueDot: isShowBlueDot,
      greenDot: !!featureData?.isBusinessMetric,
    };

    const label = (
      <SHTypography variant="subtitle2" disabled={isDisabled}>
        {first}
        <SHBox
          component={"span"}
          style={{ display: "inline-block", position: "relative" }}
        >
          {last}
          <SHBox
            component={"span"}
            style={{
              display: "inline-block",
              verticalAlign: "7px",
              marginLeft: "5px",
            }}
          >
            <SHBox display={"flex"} gap={0.5}>
              <SHDotGroup {...dotProps} />
            </SHBox>
          </SHBox>
          <SHBox
            component={"span"}
            style={{
              display: "inline-block",
              verticalAlign: "3px",
            }}
          >
            {postfixLabel}
          </SHBox>
        </SHBox>
      </SHTypography>
    );

    return (
      <SHStack
        direction="row"
        justifyContent="space-between"
        width={{ xs: "100%", md: 520 }}
        mb={1}
        columnGap={3}
      >
        <SHStack
          direction="row"
          alignItems={"center"}
          width={allowedVisibility ? "40%" : "100%"}
        >
          <SHLabel label={label} />
        </SHStack>

        {allowedVisibility && (
          <SHStack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            width={"60%"}
          >
            <SHTypography disabled={isDisabled} variant="subtitle2">Visible to</SHTypography>
            <Controller
              name={featureDataPath.viewingGroupSelections}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SelectViewingGroups
                  disabled={isDisabled}
                  sx={{
                    flex: 1,
                  }}
                  onChange={(value) => {
                    other.onChange(value);
                    debounceVisibilityChange(value);
                  }}
                  value={
                    (getValues(featureDataPath.viewingGroupSelections) as
                      | ViewingGroupSelectionDTO[]
                      | null) || []
                  }
                  viewingGroupData={featureData.viewingGroupSelections ?? []}
                />
              )}
            />
          </SHStack>
        )}
      </SHStack>
    );
  };

  return (
    <SHStack width={{ xs: "100%", md: 520 }} gap={1}>
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SHRichTextEditor
            value={toEditManagerNativeValue(value, FieldTypeId.TextLong)}
            disabled={isDisabled}
            label={renderControllerLabel()}
            onChange={(newValue) => {
              onChange(newValue);
              debouncedHandleInputChange(newValue);
            }}
            maxLength={MAX_LONG_TEXT_LENGTH}
            height={165}
          />
        )}
      />
    </SHStack>
  );
};
