import { Controller, useFormContext } from "react-hook-form";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { debounce, isEmpty, isEqual } from "lodash";
import { useState } from "react";
import { S3ObjectDTO } from "@models/core";
import { SHStack, SHTypography } from "@components/design-systems";
import { GalleryField } from "@pages/suppliers/_id/_products/_id/edit/components/gallery-field";
import { useMount } from "react-use";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";

export const EditManagerImageGalleryInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot } = useInputDotIndicator<InvestmentProductManagerDTO>(
    {
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    },
  );

  const [inputValue, setInputValue] = useState<S3ObjectDTO[]>([]);

  useMount(() => {
    const initialValue = getSafeValue(featureData.productData?.value);
    setInputValue(initialValue);
  });

  const getSafeValue = (value?: string): S3ObjectDTO[] => {
    try {
      return JSON.parse(value || "");
    } catch (error) {
      return [];
    }
  };
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      ignoreDataStatus: true,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });

    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <SHStack width={{ xs: "100%", md: 520 }} gap={1}>
      <Controller
        control={control}
        name={featureProductDataPath.value}
        render={({ field: { ref, ...other } }) => (
          <SHStack width="100%">
            <GalleryField
              disabled={isDisabled}
              title={featureData.name}
              value={inputValue}
              direction="column"
              isShowDivider={false}
              dotGroupProps={{
                orangeDot: isShowOrangeDot,
                greenDot: !!featureData?.isBusinessMetric,
              }}
              onChange={(newValue) => {
                const stringValue = JSON.stringify(newValue);
                setInputValue(getSafeValue(stringValue));

                const formValue = isEmpty(newValue) ? "" : stringValue;
                other.onChange(formValue);
                debouncedHandleInputChange(formValue);
              }}
            />
          </SHStack>
        )}
      />
      {featureData.allowedVisibilitySetting && (
        <SHStack direction={"row"} alignItems={"center"} gap={2}>
          <SHTypography variant="subtitle2" disabled={isDisabled}>
            Visible to
          </SHTypography>
          <Controller
            name={featureDataPath.viewingGroupSelections}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SelectViewingGroups
                disabled={isDisabled}
                sx={{
                  flex: 1,
                }}
                onChange={(value) => {
                  other.onChange(value);
                  debounceVisibilityChange(value);
                }}
                value={
                  (getValues(featureDataPath.viewingGroupSelections) as
                    | ViewingGroupSelectionDTO[]
                    | null) || []
                }
                viewingGroupData={featureData.viewingGroupSelections ?? []}
              />
            )}
          />
        </SHStack>
      )}
    </SHStack>
  );
};
