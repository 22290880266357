import { SHHtmlBlock, SHLabel, SHStack } from "@components/design-systems";
import { SHNumericFieldV2 } from "@components/design-systems/sh-numeric-field-v2";
import {
  useEditModelPaths,
  useInputDotIndicator,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import {
  InvestmentProductConfigurationModelsFeature,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { InvestmentBannerStatus } from "@models/product/investment-product/enums/status";
import { DEBOUNCE_DELAY } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import { RootState } from "@redux/store";
import { debounce, isEmpty, isEqual, toString } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
interface ModelFeaturePercentageProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationModelsFeature;
}

export const ModelFeaturePercentageField = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
}: ModelFeaturePercentageProps) => {
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const originalValue = feature.productData?.value;
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  /** Hook */
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const { featureProductDataPath, featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );
  /** State */

  /** Handler */
  const handleInputChange = (newValue: string | null) => {
    setValue(
      featureProductDataPath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(
      featurePath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(featurePath.isModified, !isEqual(newValue, originalValue));
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalValue),
    );
    const publishedValue = getValues(featureProductDataPath.publishedValue);

    if (!isEqual(publishedValue, newValue)) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    handleUpdateSectionData();
  };

  const debouncedHandleInputChange = debounce(
    handleInputChange,
    DEBOUNCE_DELAY,
  );

  /** Side effect */

  return (
    <>
      <SHStack>
        <SHLabel
          dotGroupProps={{
            orangeDot: isShowOrangeDot,
            blueDot: isShowBlueDot,
            greenDot: feature.isBusinessMetric,
          }}
          label={feature.name}
        />
        <SHHtmlBlock variant="body3" content={feature.description} />
      </SHStack>
      <SHStack>
        <Controller
          control={control}
          name={featureProductDataPath["value"]}
          render={({ field: { ref, ...other } }) => (
            <SHNumericFieldV2
              disabled={isSaving || isPendingApproval}
              sx={{
                "& .MuiInput-root > input": {
                  padding: "10px 0px 8px 0px",
                },
                width: "520px",
              }}
              suffix="%"
              thousandSeparator={true}
              fixedDecimalScale
              valueIsNumericString
              placeholder={"e.g. Enter 0.25 for 0.25%"}
              value={toString(getValues(featureProductDataPath.value))}
              onValueChange={({ value }) => {
                const actualValue = isEmpty(value) ? null : value;
                other.onChange(actualValue);
                debouncedHandleInputChange(actualValue);
              }}
            />
          )}
        />
      </SHStack>
    </>
  );
};
