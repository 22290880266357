import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { generateFeatureName } from "@utils/text-format";
import {
  SHBooleanField,
  SHDivider,
  SHLabel,
  SHRichTextEditor,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { ChangeEvent, useMemo } from "react";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import {
  toNativeValue,
  toStringValue,
} from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { debounce, defaultTo, isEqual } from "lodash";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import {
  getFeatureDataPath,
  getShowHideNoRoleFieldStatus,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/utils";

interface SeriesFeatureYesNoProps {
  feature: InvestmentProductConfigurationFeature;
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  selectedIndex: [number, number];
  isDisabled?: boolean;
}

const SeriesFeatureYesNo = ({
  feature,
  investmentProductSeriesData,
  selectedIndex = [0, 0],
  isDisabled = false,
}: SeriesFeatureYesNoProps) => {
  const { control, setValue, getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const featureName = generateFeatureName(
    feature.name ?? "",
    feature.description,
  );
  const {
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataStatusPath,
    featureProductDataModifiedPath,
    featureProductDataAdditionDataPath,
    featureProductDataPublishedPath,
    investmentSeriesDataHighlightTypePath,
    investmentSeriesDataModifiedPath,
    featureProductDataPublishedAdditionDataPath,
    featureViewingGroupSelectionsPath,
    dropDownFeaturePath,
  } = getFeatureDataPath(selectedIndex, investmentProductSeriesData, feature);
  const watchedValue = useWatch({
    control,
    name: featureProductDataPath,
  });
  const productDataValue = useMemo(
    () => getValues(featureProductDataPath),
    // eslint-disable-next-line
    [watchedValue, selectedIndex],
  );
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductHighlightTypePath,
      dataStatus: featureProductDataStatusPath,
    },
  });

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    const isModified = !isEqual(feature.viewingGroupSelections, newValue);
    setValue(investmentSeriesDataModifiedPath, isModified);
    setValue(sectionModifiedPath, isModified);
    setValue(featureModifiedPath, isModified);
    setValue(featureProductDataModifiedPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );
  const handleBooleanChange = (
    _: ChangeEvent<HTMLInputElement>,
    inputValue?: boolean | null,
  ) => {
    const publishedData = toStringValue(
      getValues(featureProductDataPublishedPath),
      FieldTypeId.YesNo,
    );
    const inputValueStr = toStringValue(inputValue, FieldTypeId.YesNo);

    const isModified = !isEqual(inputValueStr, feature.productData?.value);
    let highlightType: HighlightType | null;
    let dataStatus: InvestmentDataStatus | null;

    highlightType = !isEqual(publishedData, inputValueStr)
      ? HighlightType.Edited
      : null;

    dataStatus = !!inputValueStr
      ? InvestmentDataStatus.Filled
      : InvestmentDataStatus.MissingData;

    const newValueIsTrue = isEqual(inputValueStr, "true");
    if (newValueIsTrue) {
      const publishedAdditionalValue =
        getValues(featureProductDataPublishedAdditionDataPath) ?? "";
      const additionalValue =
        getValues(featureProductDataAdditionDataPath) ?? "";

      const additionalValueEdited = !isEqual(
        publishedAdditionalValue,
        additionalValue,
      );

      highlightType = additionalValueEdited
        ? HighlightType.Edited
        : highlightType;
    }

    setValue(sectionModifiedPath, true);
    setValue(investmentSeriesDataModifiedPath, true);
    setValue(featureModifiedPath, isModified);
    setValue(featureProductDataModifiedPath, isModified);
    setValue(investmentSeriesDataHighlightTypePath, highlightType);
    setValue(featureProductHighlightTypePath, highlightType);
    setValue(featureProductDataStatusPath, dataStatus);
    setValue(featureProductDataPath, inputValueStr);
  };
  const handleAdditionValueChange = (inputValue: string) => {
    const publishedAdditionalValue = defaultTo(
      getValues(featureProductDataPublishedAdditionDataPath),
      "",
    );

    let highlightType: HighlightType | null;

    highlightType = !isEqual(publishedAdditionalValue, inputValue)
      ? HighlightType.Edited
      : null;
    const isAdditionalValueModified = !isEqual(
      inputValue,
      feature.productData?.additionalValue,
    );

    const booleanValue = getValues(featureProductDataPath) ?? "";
    const publishedBooleanValue =
      getValues(featureProductDataPublishedPath) ?? "";
    const booleanValueEdited = !isEqual(booleanValue, publishedBooleanValue);
    highlightType = booleanValueEdited ? HighlightType.Edited : highlightType;

    setValue(featureProductHighlightTypePath, highlightType);
    setValue(featureProductDataModifiedPath, isAdditionalValueModified);
    setValue(featureModifiedPath, isAdditionalValueModified);
    setValue(sectionModifiedPath, true);
    setValue(investmentSeriesDataModifiedPath, true);
    setValue(featureProductDataAdditionDataPath, inputValue);
  };

  const renderBooleanField = () => {
    return (
      <Controller
        control={control}
        name={featureProductDataPath as any}
        render={({ field: { ref, onChange, ...other } }) => {
          return (
            <SHBooleanField
              value={toNativeValue(other.value, FieldTypeId.YesNo)}
              onChange={(event, value?: boolean | null) => {
                onChange(toStringValue(value, FieldTypeId.YesNo));
                handleBooleanChange(event, value);
              }}
              label={feature.name}
              disabled={isDisabled}
            />
          );
        }}
      />
    );
  };
  const renderAdditionalValueField = () => {
    if (feature.hasAdditionalValue && productDataValue === "true") {
      return (
        <Controller
          control={control}
          name={featureProductDataAdditionDataPath as any}
          render={({ field: { ref, onChange, ...other } }) => {
            return (
              <SHRichTextEditor
                sx={{ width: "100% important" }}
                {...other}
                label={EditProductDefaults.AdditionalFieldTitle}
                maxLength={MAX_LONG_TEXT_LENGTH}
                onChange={handleAdditionValueChange}
                disabled={isDisabled}
              />
            );
          }}
        />
      );
    }

    return <></>;
  };

  if (
    !investmentProductSeriesData ||
    getShowHideNoRoleFieldStatus(getValues, dropDownFeaturePath)
  ) {
    return <></>;
  }

  return (
    <>
      <SHDivider />
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        gap={2}
        sx={{
          pt: "10px",
          pb: "5px",
          wordBreak: "break-word",
        }}
        key={featureProductDataPath}
        width={"100%"}
      >
        <SHStack width={"39%"} gap={2} justifyContent={"space-between"}>
          <SHStack>
            <SHLabel
              dotGroupProps={{
                orangeDot: isShowOrangeDot,
                blueDot: isShowBlueDot,
              }}
              label={
                <SHTypography variant="subtitle2">
                  {featureName.first}
                  {featureName.last}
                </SHTypography>
              }
            />
            {feature?.description && (
              <SHTypography>{feature.description}</SHTypography>
            )}
          </SHStack>
          {feature.allowedVisibilitySetting && (
            <SHStack width={"100%"} direction={"row"} alignItems={"center"}>
              <SHLabel
                label={
                  <SHTypography disabled={isDisabled} variant="subtitle2">
                    Visible to
                  </SHTypography>
                }
              />
              <Controller
                name={featureViewingGroupSelectionsPath}
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SelectViewingGroups
                    sx={{
                      width: "70%",
                    }}
                    onChange={(value) => {
                      other.onChange(value);
                      debounceVisibilityChange(value);
                    }}
                    value={
                      (getValues(featureViewingGroupSelectionsPath) as
                        | ViewingGroupSelectionDTO[]
                        | null) || []
                    }
                    viewingGroupData={feature.viewingGroupSelections ?? []}
                    disabled={isDisabled}
                  />
                )}
              />
            </SHStack>
          )}
        </SHStack>
        <SHStack width={"61%"}>
          {renderBooleanField()}
          {renderAdditionalValueField()}
        </SHStack>
      </SHStack>
    </>
  );
};

export default SeriesFeatureYesNo;
