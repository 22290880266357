import React, { useMemo } from "react";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { FieldTypeId } from "@models/configuration";
import SeriesFeatureTable from "../table-input-controller";
import SeriesFeatureDateTime from "../date-time-input-controller";
import SeriesFeatureTextLong from "../long-text-input-controller";
import SeriesFeatureYesNo from "../yes-no-input-controller";
import SeriesFeatureGallery from "../image-gallery-input-controller";
import SeriesFeatureDropdown from "../drop-down-input-controller";

interface EditSeriesPageRightPaneProps {
  selectedIndex?: [number, number];
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  isDisabled?: boolean;
}

const EditSeriesRightPanel = ({
  selectedIndex = [0, 0],
  investmentProductSeriesData,
  isDisabled = false,
}: EditSeriesPageRightPaneProps) => {
  const selectedSection = useMemo(() => {
    return investmentProductSeriesData!.sections?.[selectedIndex[1]];
  }, [investmentProductSeriesData, selectedIndex]);
  const textShortFeature = useMemo(() => {
    return (
      selectedSection?.features?.filter(
        (feature) => feature.fieldType?.id === FieldTypeId.TextShort,
      ) ?? []
    );
  }, [selectedSection]);

  const renderFeatureItem = (
    feature: InvestmentProductConfigurationFeature,
    featureIdx: number,
  ) => {
    switch (feature.fieldType?.id) {
      case FieldTypeId.Slideshow: {
        return (
          <SeriesFeatureGallery
            feature={feature}
            selectedIndex={selectedIndex}
            investmentProductSeriesData={investmentProductSeriesData}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.TextLong: {
        return (
          <SeriesFeatureTextLong
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.TextShort: {
        if (feature.order === textShortFeature[0]?.order) {
          return (
            <SeriesFeatureTable
              features={textShortFeature}
              selectedIndex={selectedIndex}
              tableHeaderName={investmentProductSeriesData?.name}
              isDisabled={isDisabled}
            />
          );
        }
        return;
      }
      case FieldTypeId.DateTime: {
        return (
          <SeriesFeatureDateTime
            key={featureIdx}
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.YesNo: {
        return (
          <SeriesFeatureYesNo
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      case FieldTypeId.Dropdown: {
        return (
          <SeriesFeatureDropdown
            feature={feature}
            investmentProductSeriesData={investmentProductSeriesData}
            selectedIndex={selectedIndex}
            isDisabled={isDisabled}
          />
        );
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      {selectedSection?.features?.map(
        (
          feature: InvestmentProductConfigurationFeature,
          featureIdx: number,
        ) => (
          <React.Fragment key={feature.id}>
            {renderFeatureItem(feature, featureIdx)}
          </React.Fragment>
        ),
      )}
    </>
  );
};

export default EditSeriesRightPanel;
