import { SHHtmlBlock, SHStack } from "@components/design-systems";
import { useTheme } from "@mui/material";
import { ReadMoreButton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature-model/components/read-more";
import React, { useEffect, useRef, useState } from "react";
interface ExpandableHtmlBlockProps {
  value: string;
}

const ExpandableHtmlBlock: React.FC<ExpandableHtmlBlockProps> = ({ value }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const { palette } = useTheme();

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        setIsOverflowing(contentRef.current.scrollHeight > 90);
      }
    };

    checkOverflow(); // Check initially
    window.addEventListener("resize", checkOverflow); // Check on resize

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  return (
    <>
      <SHStack
        ref={contentRef}
        sx={{
          maxHeight: "90px",
          overflow: "hidden",
          color: palette.text.fourth,
          fontWeight: 500,
          fontSize: "0.813rem",
          fontFamily: "Epilogue",
        }}
      >
        <SHHtmlBlock textAlign={"left"} content={value} />
      </SHStack>

      {isOverflowing && <ReadMoreButton content={value} />}
    </>
  );
};

export default ExpandableHtmlBlock;
