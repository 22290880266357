import { CloseButton } from "@components/buttons/close";
import {
  SHDialog,
  SHTypography,
  SHStack,
  SHButton,
} from "@components/design-systems";
import { FooterSVG } from "@components/svgs";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import mainBgImage from "@assets/images/background/main.svg";

import React from "react";
export interface ModelPerformanceDisclaimerDialogProps {
  onClose?: () => void;
}
const ModelPerformanceDisclaimerDialog = ({
  onClose,
}: ModelPerformanceDisclaimerDialogProps) => {
  const { palette } = useTheme();

  return (
    <SHDialog
      open
      disableEscapeKeyDown
      disableBackdropClick
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          padding: 0,
          overflow: "hidden",
          backgroundImage: `url(${mainBgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: { xs: "100%", md: 700 },
          border: 0,
        },
      }}
    >
      <DialogTitle
        display={"flex"}
        flexDirection="row"
        alignItems="stretch"
        justifyContent="space-between"
        padding={"0px !important"}
        borderBottom={`1px solid ${palette.common.black}`}
      >
        <SHTypography
          variant="h2"
          fontWeight={600}
          fontSize={20}
          padding={"10px 40px !important"}
          borderRight={`1px solid ${palette.common.black}`}
          textAlign={"center"}
          textTransform={"uppercase"}
        >
          Performance and risk calculator
        </SHTypography>
        <SHStack padding={"21px 31px"}>
          <CloseButton width="8.75px" height="8.75px" onClick={onClose} />
        </SHStack>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px !important", overflow: "auto" }}>
        <SHStack textAlign={"justify"} marginY={"44px"} ml={"40px"} mr={"20px"}>
          <SHTypography variant="subtitle2" textTransform={"uppercase"}>
            Performance and risk disclaimer: General
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            This information is provided for general use only. SuitabilityHub
            displays information sourced from our users and other third parties,
            and you must make your own enquiries as to the accuracy and
            completeness of this information. No representation or warranty
            (either express or implied) is given as to the truth, accuracy or
            completeness of the information.
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Any results provided by the portfolio returns and risk measures
            calculator are illustrative only and rely upon information that may
            change over time. These results should not be relied upon for the
            purpose of making a decision in relation to a particular financial
            product, platform or provider. SuitabilityHub does not provide
            financial advice or make recommendations about the suitability of
            any financial product, platform, or provider. Any result provided by
            our calculator is not an offer, and financial product providers will
            undertake their own fee calculations.
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Performance information is sourced directly from product providers,
            and subject to the provider’s methodology. Past performance is not a
            reliable indicator of future performance. The information is based
            on a notional portfolio run on the provider’s nominated platform and
            does not take into account differences with the actual portfolio
            implementation. Unless otherwise disclosed, returns are: net of
            portfolio management fees, performance fees and underlying
            investment fees; gross of platform fees; before taxes; and assume
            reinvestment of distributions.
          </SHTypography>
          <SHTypography variant="body2" mt="15px">
            Returns may vary across platforms due to factors including
            differences in platform fees, the timing and implementation of
            trades, and individual investor circumstances.
          </SHTypography>
        </SHStack>
        <FooterSVG style={{ marginBottom: "-5px" }} />
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          borderTop: `1px solid ${palette.common.black}`,
          height: 110,
          margin: 0,
        }}
      >
        <SHButton
          color="primary"
          variant="contained"
          onClick={onClose}
          size="large"
          sx={{ width: "260px" }}
        >
          Close
        </SHButton>
      </DialogActions>
    </SHDialog>
  );
};

export default ModelPerformanceDisclaimerDialog;
